var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch, Ref, namespace } from 'nuxt-property-decorator';
const ProfileModule = namespace('adminProfile');
let PublicNotificationsBell = class PublicNotificationsBell extends Vue {
    constructor() {
        super(...arguments);
        this.isShow = true;
    }
    mounted() {
        if (this.unviewed_releases_count) {
            this.startSwingAnimation();
        }
    }
    startSwingAnimation() {
        const icon = this.notificationIcon;
        const dot = this.notificationDot;
        if (!icon || !dot)
            return;
        const speed = 0.01;
        const amplitude = 30;
        const swingAnimation = (time) => {
            const swingAngle = Math.sin(time * speed) * amplitude;
            icon.style.transform = `rotate(${swingAngle}deg)`;
            const dotScale = Math.abs(Math.sin(time * speed));
            dot.style.transform = `scale(${dotScale})`;
            if (time * speed >= Math.PI * 2) {
                icon.style.fill = '#F6CA50'; // Change to gold color
            }
            requestAnimationFrame(swingAnimation);
        };
        requestAnimationFrame(swingAnimation);
    }
    onNewsCountChange(newVal) {
        this.isShow = false;
        this.$nextTick(() => {
            this.isShow = true;
            if (!newVal)
                return;
            setTimeout(() => {
                this.startSwingAnimation();
            }, 100);
        });
    }
};
__decorate([
    ProfileModule.State('unviewed_releases_count'),
    __metadata("design:type", Object)
], PublicNotificationsBell.prototype, "unviewed_releases_count", void 0);
__decorate([
    Ref(),
    __metadata("design:type", typeof (_a = typeof SVGElement !== "undefined" && SVGElement) === "function" ? _a : Object)
], PublicNotificationsBell.prototype, "notificationIcon", void 0);
__decorate([
    Ref(),
    __metadata("design:type", typeof (_b = typeof HTMLElement !== "undefined" && HTMLElement) === "function" ? _b : Object)
], PublicNotificationsBell.prototype, "notificationDot", void 0);
__decorate([
    Watch('unviewed_releases_count'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PublicNotificationsBell.prototype, "onNewsCountChange", null);
PublicNotificationsBell = __decorate([
    Component
], PublicNotificationsBell);
export default PublicNotificationsBell;
