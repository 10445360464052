const defaultState = () => ({
    id: 0,
    name: '',
    surname: '',
    email: '',
    photo: '',
    photo_color: '',
    role: 'Администратор',
    errors: '',
    superAdmin: null,
    unviewed_releases_count: 0
});
export default defaultState;
