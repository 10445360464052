import router from '@/router';
import lessonTypes from '@/helpers/enteties/LessonTypes';
import { mixinMutations } from '@/store/mixins';
import { constructorTabs, tabs } from './entities/tabs';
import defaultState from './entities/defaultState';
import fieldTypes from './entities/fieldTypes';
const freezeState = { ...defaultState };
const state = defaultState;
const mutations = {
    ...mixinMutations(),
    setState(state, payload) {
        if (!payload)
            return false;
        Object.entries(payload).forEach(([key, value]) => {
            if (state.hasOwnProperty(key) && (typeof value === 'boolean' || value)) {
                state[key] = value;
            }
            if (key === 'name' && !value) {
                state.name = 'Без названия';
            }
        });
    },
    clearState() {
        Object.entries(freezeState).forEach(([key, value]) => {
            state[key] = value;
        });
    },
    setFields(state, data) {
        state.fields = data.map(({ id, type, name, multiple }) => {
            const currentField = fieldTypes.find((item) => item.value === type);
            return {
                icon: currentField ? currentField.icon : '',
                rusType: currentField ? currentField.text : '',
                type,
                name,
                multiple,
                id
            };
        });
    },
    setCourseCustomFields(state, data) {
        state.courseCustomFields = data.map((item) => {
            const { custom_field, required, id } = item;
            const { type, name } = custom_field;
            const currentField = fieldTypes.find((item) => item.value === type);
            return {
                icon: currentField ? currentField.icon : '',
                rusType: currentField ? currentField.text : '',
                type,
                name,
                required,
                id,
                field_id: custom_field.id
            };
        });
    },
    changeUserAccess(state, { user, action }) {
        if (action === 'attach')
            state.admin_access_users = [...state.admin_access_users.filter(({ id }) => id !== user.id), user];
    },
    setItems(state, { title, data }) {
        const items = data
            .map((branch) => branch.course_packages_by_sort.map((item) => ({ ...item, branch_name: branch.name, branch_sort: branch.sort })))
            .flat().sort((a, b) => a.branch_sort + a.sort - (b.branch_sort + b.sort));
        state.selectTitle = title;
        state.selectItems = items;
        state.selectLoading = false;
    },
    setNewHeaders(state, payload) {
        const currentHeadersLength = state.defaultHeaders.length;
        const headersValues = state.defaultHeaders.map((item) => item.value);
        const coursesCustomValues = headersValues.filter((value) => String(value).includes('custom_field_'));
        const newHeaders = payload.map(({ id, name, type, multiple, subtype }, index) => ({
            text: name,
            value: `custom_field_${id}`,
            // sortable: false,
            sortable: !['list', 'avatar', 'file', 'text', 'telegram_id'].includes(type) && !multiple,
            type,
            sort: index + currentHeadersLength,
            checked: false,
            multiple,
            subtype: subtype || 'no_subtype',
            componentName: 'td-custom'
        }));
        const newHeadersValues = newHeaders.map((item) => item.value);
        const actualHeaders = state.defaultHeaders.filter(({ value }) => !coursesCustomValues.includes(value) || newHeadersValues.includes(value));
        state.defaultHeaders = [
            ...actualHeaders,
            ...newHeaders.filter((item) => !headersValues.includes(item.value))
        ];
    }
};
const actions = {
    async getCourse({ commit, rootGetters, state }, { course_id }) {
        if (!course_id || course_id === 'create')
            return;
        commit('setProperty', { name: 'baseUrl', value: `${rootGetters.API_URL}/courses/${course_id}` });
        const response = await this.getDataStore(state.baseUrl, {
            with: 'subcategoriesWithCategories;adminAccessUsers;coursePackagesToOpen.course:id,name;locals;type;bulletPoints;courseLinkChatBlocks;courseLinkChatBlocks.coursePackages',
            withExists: 'authUserFavourites',
            withCount: 'lessons'
        });
        const { status } = response.data;
        const currentStatus = state.statuses.find((item) => item.value === status);
        commit('setProperty', { name: 'currentStatus', value: currentStatus });
        const { is_constructor } = response.data;
        commit('setProperty', { name: 'tabs', value: !is_constructor ? tabs : [...tabs, ...constructorTabs] });
        commit('setState', response.data);
    },
    async getCourseOnlyNameAndPkgs({ commit, state }) {
        const { baseUrl } = state;
        const { data: { course_packages_to_open = [] } } = await this.getDataStore(baseUrl, {
            with: 'coursePackagesToOpen.course:id,name',
            only: 'name'
        });
        commit('setProperty', { name: 'course_packages_to_open', value: course_packages_to_open });
    },
    async saveCourse({ rootGetters, state }, { course_id, bitrixForm }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        const { crm_bitrix, crm_bitrix_form_id, crm_bitrix_form_url } = state;
        const params = bitrixForm ? {
            crm_form_id: bitrixForm,
            crm_bitrix: null,
            crm_bitrix_form_id: null,
            crm_bitrix_form_url: null
        } : {
            crm_form_id: null,
            crm_bitrix,
            crm_bitrix_form_id,
            crm_bitrix_form_url
        };
        return await this.postDataStore(url, params, 'PUT');
    },
    async clearCourseBitrixForm({ rootGetters, commit }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        commit('setProperty', [
            { name: 'crm_form_id', value: null },
            { name: 'crm_bitrix', value: null },
            { name: 'crm_bitrix_form_id', value: null },
            { name: 'crm_bitrix_form_url', value: null }
        ]);
        return await this.postDataStore(url, {
            crm_form_id: null,
            crm_bitrix: null,
            crm_bitrix_form_id: null,
            crm_bitrix_form_url: null
        }, 'PUT');
    },
    async getCoursesToOpen({ state, rootGetters }, payload = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${state.id}/available_courses_to_open_list`, payload);
    },
    async deleteCourse({ state, rootGetters }) {
        const url = `${rootGetters.API_URL}/courses/${state.id}`;
        setTimeout(() => {
            router.push({ name: 'CoursesList' });
        }, 1000);
        return await this.postDataStore(url, {}, 'DELETE');
    },
    async setNewCourse({ dispatch, rootGetters }, { name, value, courseType }) {
        const url = `${rootGetters.API_URL}/courses`;
        const data = {};
        data[name] = value;
        if (courseType && ['simple', 'constructor'].includes(courseType)) {
            data.is_constructor = courseType !== 'simple';
        }
        const response = await this.postDataStore(url, data, 'POST');
        await dispatch('getCourse', { course_id: response.data.id });
        // await dispatch('getCategories');
        const { id } = response.data;
        const { errors } = response;
        if (!errors) {
            const params = { course_id: id };
            await router.push({
                name: 'Course',
                params
            });
        }
        return response;
    },
    async sendPropertyAjax({ commit, dispatch, rootGetters }, { name, value, courseType, noCommit, course_id }) {
        if (course_id) {
            const url = `${rootGetters.API_URL}/courses/${course_id}`;
            const response = await this.postDataStore(url, { [name]: value, with: 'locals;galleries' }, 'PUT');
            if (!noCommit) {
                commit('setProperty', { name, value });
            }
            return response;
        }
        if (!state.baseUrl) {
            return await dispatch('setNewCourse', { name, value, courseType });
        }
        const response = await this.postDataStore(state.baseUrl, { [name]: value }, 'PUT');
        if (!noCommit) {
            commit('setProperty', { name, value });
        }
        return response;
    },
    async updateCourseMultiple({ commit, rootGetters, state }, { params, course_id, withString }) {
        const suffix = withString ? `?with=${withString}` : '';
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}${suffix}`, { ...params }, 'PUT');
        Object.keys(params).forEach((name) => {
            if (state.hasOwnProperty(name) && response.data) {
                if (name === 'course_link_chat_blocks')
                    console.log('value ->', response.data[name]);
                commit('setProperty', { name, value: response.data[name] });
            }
        });
        return response;
    },
    async getCoursesList({ commit, rootGetters }, payload = {}) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/courses`, {
            only: 'name',
            status: 'visible,close',
            ...payload
        });
        const courses = response.data.map((item) => {
            const { id, name } = item;
            return { text: name, value: id };
        });
        commit('setProperty', { name: 'courses_list', value: courses });
        return courses;
    },
    async getLessons({ commit, rootGetters }, { course_id, per_page, page, sort, desc, asc }) {
        const params = {
            only: 'name,type,max_points,min_points_complete,min_points_course',
            types: 'test,full_video,webinar,homework,offline',
            per_page,
            page
        };
        const sortParams = [{ value: sort, name: 'sort' }, { value: desc, name: 'desc' }, { value: asc, name: 'asc' }];
        sortParams.forEach(({ name, value }) => {
            if (value)
                params[name] = value;
        });
        const response = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons`, params);
        const { data, meta } = response;
        const { total } = meta;
        const result = data
            .map(({ name, type, id, max_points, min_points_complete, min_points_course }) => {
            const table_type = lessonTypes.find(({ value }) => value === type);
            return {
                name,
                type,
                id,
                max_points: max_points || '-',
                min_points_complete: min_points_complete || '-',
                min_points_course,
                table_type
            };
        });
        commit('setProperty', [
            { name: 'lessonsWithCheck', value: result },
            { name: 'tableLessonsCount', value: total }
        ]);
        return result;
    },
    async sendLessonPoints({ rootGetters }, { id, course_id, name, value }) {
        const data = {};
        data[name] = value;
        const url = `${rootGetters.API_URL}/courses/${course_id}/lessons/${id}`;
        return await this.postDataStore(url, data, 'PUT');
    },
    async getFieldsList({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            only: 'id,name,description,multiple',
            all: 1,
            subtype: 'bid'
        });
        commit('setFields', data);
        return data;
    },
    async getCourseFields({ rootGetters, commit }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields`, {
            with: 'customField:name,type',
            all: 1,
            sort: 'sort'
        });
        commit('setCourseCustomFields', data);
        return data;
    },
    async updateCourseField({ rootGetters }, { course_id, field_id, params }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/${field_id}`, { ...params }, 'PUT');
        return response.data;
    },
    async deleteCourseField({ rootGetters }, { course_id, field_id }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/${field_id}`, {}, 'DELETE');
        return response.data;
    },
    async createCourseFields({ rootGetters }, { course_id, custom_field_id }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/multi_create`, { custom_field_id }, 'POST');
        return response.data;
    },
    async updateCourseFields({ rootGetters }, { course_id, course_custom_fields }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/multi_update`, { course_custom_fields }, 'POST');
        return response.data;
    },
    async replicateCourse({ rootGetters }, course_id) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/replicate`, {}, 'POST');
        return data;
    },
    async getReplicatedCourse({ rootGetters }, replica_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_replicas/${replica_id}/after_replicated`);
        return data;
    },
    async getCourseFormValues({ rootGetters }, { course_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/custom_field_course_values`, { all: 1 });
    },
    async updateCourseFormValues({ rootGetters }, { course_id, custom_field_value_items }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/custom_field_course_values_multiple`, { custom_field_value_items });
    },
    async getCourseHistory({ rootGetters }, { course_id, filters, page = 1 }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/audits`, { ...filters, page });
    },
    async getCustomFields({ rootGetters, commit }) {
        commit('setProperty', { name: 'fieldsLoading', value: true });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            only: 'name,type',
            subtype: 'user',
            all: 1,
            'notTypes[0]': 'image',
            'notTypes[1]': 'file',
            'notTypes[2]': 'avatar',
            'notTypes[3]': 'choose_role'
        });
        commit('setNewHeaders', data);
        commit('setProperty', { name: 'fieldsLoading', value: false });
    },
    getSelectItems({ commit, state, rootGetters }, { title, type }) {
        commit('setProperty', { name: 'selectLoading', value: true });
        const vocabulary = {
            course_packages: async () => {
                const url = `${rootGetters.API_URL}/courses/${state.id}/course_branches`;
                const params = { sort: 'sort', with: 'coursePackagesBySort' };
                const { data } = await this.getDataStore(url, params);
                await commit('setItems', { title: title || '', data });
                return data;
            }
        };
        if (!vocabulary[type]) {
            return;
        }
        if (!vocabulary[type])
            commit('setProperty', { name: 'selectLoading', value: false });
        return vocabulary[type]();
    },
    async doUserAction({ rootGetters, state }, { users_id, selectedIds, type, action }) {
        const typesVocabulary = {
            course_packages: () => ({
                url: `${rootGetters.API_URL}/courses/${state.id}/${action}`,
                params: { users_ids: users_id, course_packages_ids: selectedIds }
            }),
            course_orders: () => ({
                url: `${rootGetters.API_URL}/course_orders/${action}`,
                params: { users_id, courses_id: [state.id] }
            }),
            document_kpk: () => ({
                url: `${rootGetters.API_URL}/users/set_document_kpk`,
                params: { users_ids: users_id, courses_id: [state.id], is_verified: action.includes('yes') }
            }),
        };
        if (!typesVocabulary[type])
            return { errors: 'неизвестное действие' };
        const config = typesVocabulary[type]();
        const { data } = await this.postDataStore(config.url, config.params);
        return { data };
    },
    async getUserList({ rootGetters }, { course_id, payload }) {
        const isString = typeof payload === 'string';
        const params = !isString ? payload : { search: payload };
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/students_with_package`, params);
    },
    async getCourseUserList({ rootGetters, state }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${state.id}/users`, payload);
    },
    async parseBitrixScript({ rootGetters }, { course_id, script_tag }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/parse_crm_bitrix_script_tag`;
        const params = { script_tag };
        return await this.postDataStore(url, params);
    },
    // Favorite
    async attachToFavorite({ rootGetters, commit, state }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/courses/${state.id}/user_favourite/attach`, {}, 'POST');
        if (errors)
            return { errors };
        commit('setProperty', { name: 'auth_user_favourites_exists', value: true });
        return { data };
    },
    async detachFromFavorite({ rootGetters, commit, state }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/courses/${state.id}/user_favourite/detach`, {}, 'POST');
        if (errors)
            return { errors };
        commit('setProperty', { name: 'auth_user_favourites_exists', value: false });
        return { data };
    },
    async checkUserReportReady({ rootGetters }, fileName) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${state.id}/users_file`, { file_name: fileName });
    },
    async getUserReportsList({ rootGetters, state }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${state.id}/users_download/history`);
    },
    async generateUserReport({ rootGetters, state }, filters) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${state.id}/users_download`, {
            ...filters,
            with: 'roles;groups'
        });
    },
    async getCourseLandingInfo({ rootGetters }, name) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_properties_download/${name}`);
    },
    // bullet points
    async addBulletPoint({ rootGetters }, { course_id, ...payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/add_bullet_point`, {
            ...payload,
            with: 'bulletPoints'
        });
    },
    async updateBulletPoint({ rootGetters }, { course_id, id, ...payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/update_bullet_point/${id}`, {
            ...payload,
            with: 'bulletPoints'
        }, 'PUT');
    },
    async deleteBulletPoint({ rootGetters }, { course_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/delete_bullet_point/${id}`, {}, 'DELETE');
    },
    async addPackageToLink({ rootGetters, state }, { link_id, package_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${state.id}/course_link_chat_blocks/${link_id}/attach_course_packages`, { course_packages_ids: [package_id] });
    },
    async deletePackageFromLink({ rootGetters, state }, { link_id, package_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${state.id}/course_link_chat_blocks/${link_id}/detach_course_packages`, { course_packages_ids: [package_id] });
    },
    async updateUserStatistics({ rootGetters }, course_id) {
        return await this.postDataStore(`${rootGetters.API_URL}/refresh_user_statistics/${course_id}`, {}, 'POST');
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
