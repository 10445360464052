import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async getScriptsList({ rootGetters, commit }, { page = 1 }) {
        return await this.getDataStore(`${rootGetters.API_URL}/scripts`, { page: page, per_page: 20 });
    },
    async createScript({ rootGetters, commit, state }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/scripts`, payload, 'POST');
        commit('setProperty', { name: 'scripts', value: [...state.scripts, data] });
        return data;
    },
    async removeScript({ rootGetters, commit, state }, script_id) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/scripts/${script_id}`, {}, 'DELETE');
        commit('setProperty', { name: 'scripts', value: state.scripts.filter(({ id }) => id !== script_id) });
        return data;
    },
    async updateScript({ rootGetters, state }, { script_id, payload }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/scripts/${script_id}`, { ...payload }, 'PUT');
        const currentScript = state.scripts.filter(({ id }) => id !== script_id);
        Object.entries(payload).forEach(([key, value]) => {
            currentScript[key] = value;
        });
        return data;
    },
    async multiUpdate({ rootGetters, commit }, payload) {
        commit('setProperty', { name: 'scripts', value: payload });
        const dataToSend = payload.map(({ id, sort }) => ({ id, sort }));
        await this.postDataStore(`${rootGetters.API_URL}/scripts/sort`, { scripts: dataToSend });
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
